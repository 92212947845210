export const name = "Lino Marmo";
export const title = "Owner of Mindinvaders web media solutions";
export const email = "marmopasquale@gmail.com";
export const github = "https://github.com/lmarmo2023";
export const linkedin = "https://linkedin.com/in/mindwebmedia/";
export const twitter = "https://x.com/marmopasquale";
export const applemusic = "https://music.apple.com/us/album/blinding-lights-single/1488408555";
export const spotify = "https://open.spotify.com/playlist/0rp5i75Rt8WIXWdfzPpJXQ";
export const twitch = "https://www.twitch.tv/pewdiepie";
export const leetcode = "https://leetcode.com/kingsmen732/";
export const figma = "https://www.figma.com/@KIngsmen732";
export const discord = "https://discord.com/users/kingmen732";

export const skills = [
    "Full stack developer",
    "Wordpress",
    "React",
    "AWS",
    "Pipeline",
];

export const resume = "./Pasquale_Marmo_Curriculum.pdf"; //resume is working fine now
export const contactFormsparkId = '6F5Tv1aci'
export const googleApyKey = 'AIzaSyBhwNX9BvVyYacj2mV0TXdq4OGCqrL3KQk'



