import React from 'react';
import MainContainer from "./components/MainContainer";
import { useState } from 'react';
import Slider from './components/slider';

function App() {
  return (
    <div>
      <Slider />
    </div>
  );
}

export default App;
