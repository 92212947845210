import React from "react";

const Education = ({ isDarkMode }) => {
  const educationDetails = [
    {
      institution: "Alkemy - Milano, MI, Italy",
      duration: "06/2018 - today",
      cgpa: "<ul>\n" +
          "    <li>Sviluppo e gestione di progetti</li>\n" +
          "    <li>Maintenance e sviluppo su siti corporate gestiti da Alkemy</li>\n" +
          "    <li>AWS</li>\n" +
          "    <li>React.js</li>\n" +
          "    <li>WordPress</li>\n" +
          "    <li>Magento 2</li>\n" +
          "    <li>Drupal 9</li>\n" +
          "    <li>Sistemi di virtualizzazione: AWS, Amazon S3, Docker</li>\n" +
          "    <li>Sviluppo e gestione DEM</li>\n" +
          "</ul>\n",
      degree: "senior developer",
    },
    {
      institution: "Mindinvaders - Pietragalla, PZ, Italy",
      duration: "2014 today",
      degree: "Owner",
      cgpa: "<ul>\n" +
          "    <li>Hosting su server Dedicato, </li>\n" +
          "    <li>Sviluppo su piattaforma open source di e-commerce e siti aziendali.</li>\n" +
          "</ul>\n",
    },

  ];

  return (
    <div
      className={`flex flex-col justify-center items-center min-h-screen w-full p-6 ${
        isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-black"
      }`}
    >
      <div
        className={`max-w-4xl w-full rounded-lg shadow-md mb-7 p-6 ${
          isDarkMode ? "bg-gray-900" : "bg-gray-100"
        }`}
      >
        <h2
          className={`text-2xl md:text-3xl font-bold text-center mb-6 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          Experience details
        </h2>

        {educationDetails.map((edu, index) => (
          <div
            key={index}
            className={`p-4 mb-6 rounded-lg shadow-sm transition-all transform hover:scale-105 ${
              isDarkMode
                ? "bg-gray-800 text-gray-200"
                : "bg-white text-gray-800"
            }`}
          >
            <h3
              className={`text-xl md:text-2xl font-semibold ${
                isDarkMode ? "text-white" : "text-gray-800"
              }`}
            >
              {edu.institution}
            </h3>
            <p
              className={`text-sm md:text-base ${
                isDarkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              {edu.duration}
            </p>
            <p className="italic text-sm md:text-base">{edu.degree}</p>
            {edu.cgpa && <div className="mt-1 text-sm md:text-base"><div dangerouslySetInnerHTML={{ __html: edu.cgpa }}></div></div>}
            {edu.score && <p className="mt-1 text-sm md:text-base">{edu.score}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
