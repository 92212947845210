import React from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { googleApyKey as apikey } from "../profileconfig";
import backgroundImage from "../assets/profile.jpg";

const mapStyles = {
    width: "100%",
    height: "400px",
};

const GoogleMap = (props) => {
    return (
        <Map
            google={props.google}
            zoom={14}
            style={mapStyles}
            initialCenter={{
                lat: 40.74764888621892,
                lng: 15.880171968866692, // Example: San Francisco
            }}
        >
            <Marker position={{ lat: 40.74764888621892, lng: 15.880171968866692 }} />
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: `${apikey}`, // Replace with your API key
})(GoogleMap);