import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import santander from "../assets/partner/santander.svg";
import selleItalia from "../assets/partner/selle-italia.png";
import callmewine from "../assets/partner/callmewine.svg";
import mailboxesetc from "../assets/partner/mailboxesetc.svg";
import fratelliOrsero from "../assets/partner/orsero.svg";
import viviEnergia from "../assets/partner/vivienergia.svg";
import lgBusiness from "../assets/partner/lg-business.png";
import ajoenergia from "../assets/partner/ajo.png";
import webuildvalue from "../assets/partner/webuildvalue.png";

import 'swiper/css';
import React from "react";

const PartnerSwiper = () => {
    const partners = [
        { url: 'https://www.santanderconsumer.it/', name: 'Banca Santander', logo: santander },
        { url: 'https://www.selleitalia.com/', name: 'SelleItalia', logo: selleItalia  },
        { url: 'https://www.callmewine.com/', name: 'Callmewine', logo: callmewine },
        { url: 'https://www.mbe-franchising.it/', name: 'Mail Boxes etc', logo: mailboxesetc },
        { url: 'https://www.fratelliorsero.it/', name: 'Fratelli Orsero', logo: fratelliOrsero },
        { url: 'http://vivienergia.it/', name: 'ViviEnergia', logo: viviEnergia },
        { url: 'https://www.lgbusiness.it/', name: 'LG Business', logo: lgBusiness },
        { url: 'http://ajoenergia.it/', name: 'AJO Energia', logo: ajoenergia },
        { url: 'https://www.webuildvalue.com/it/', name: 'We Build Value', logo: webuildvalue },
    ];

    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
            <Swiper
                modules={[Autoplay]}
                spaceBetween={30}
                slidesPerView={2}
                loop={true}
                centeredSlides={true}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 5 }
                }}
            >
                {partners.map((partner, index) => (
                    <SwiperSlide key={index}>
                        <a
                            href={partner.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex flex-col items-center justify-center hover:opacity-75 transition-opacity"
                        >
                            <div className="w-40 h-20 flex items-center justify-center mb-2">
                                <div className="w-40 h-20 flex items-center justify-center mb-2">
                                    <img
                                        src={partner.logo}
                                        alt={`${partner.name} logo`}
                                        className="w-full h-full object-contain"
                                    />
                                </div>

                            </div>
                            <p className="text-center text-sm font-medium text-gray-600">
                                {partner.name}
                            </p>
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default PartnerSwiper;
