
import React from 'react';
import PartnerSwiper from "./PartnerSwiper";

const getCurrentYear = new Date().getFullYear();

const Footer = () => {
  return (
      <div className="w-full py-4 text-center">
        <div className="dark">
        <p className="text-center text-sm sm:text-base lg:text-lg ">Some projects i've worked on</p>
          <PartnerSwiper />
        </div>
    <footer className="w-full py-4 text-center">
      <p className="text-sm sm:text-base lg:text-lg ">
        &copy; {getCurrentYear} Mind Invaders -  Via Mario Pagano, 19 85016 Pietragalla PZ - P.IVA: IT01964660763

      </p>
    </footer>
      </div>
  );
};

export default Footer;
